.modal-content {
  background-color: white;
  border-radius: 0.213333rem;
  padding: 0.533333rem;
  width: 8rem;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}